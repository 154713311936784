<template>
  <div>
    <div class="lg:mx-8 mx-5 md:py-10 py-7"> 
      <div class="card rounded-lg lg:p-10 sm:p-6 p-4">
        <p class="text-darkblue font-semibold md:text-xl text-lg">{{ $t('Hello') }} {{userData.full_name}},</p>
        <div class="font-medium text-base text-darkgray md:mt-6 mt-4">
          <p>{{ $t('Start by shopping online from your favorite stores anywhere in Egypt!') }}</p>
          <p>
            <span>{{ $t('During checkout') + ',' + ' ' }}</span> 
            <span class="text-blue">{{ ('use your ShipBlu box address as your shipping address in Egypt.') }}</span>
          </p>
        </div>
      </div>
      <div class="mt-6 card rounded-lg lg:p-10 sm:p-6 p-4">
        <p class="text-eggplant capitalize font-semibold md:text-xl text-lg">{{ $t('ShipBlu box address') }}</p>
        <div class="card lg:p-6 sm:p-4 p-3 md:mt-6 mt-4">
          <div class="grid md:grid-cols-2 grid-cols-1 lg:gap-4 gap-2">
            <div class="small-card sm:p-4 py-3 px-2 flex items-center justify-between">
              <p class="lg:text-base sm:text-sm text-xs mr-1">
                <span class="text-darkblue">{{ $t('Your Address in Egypt:') }}</span>
                <span class="font-semibold text-blue ml-1">{{ userData.po_box.box_number }}, {{ $t('4 Nile St') }}</span>
              </p>
              <div class="relative inline-block">
                <span id="address" class="tooltip text-xs py-1 px-2 hidden">{{ $t(tooltipText) }}</span>
                <img @click="copied(`${userData.po_box.box_number}, 4 Nile St`, 'address')" class="sm:w-4 w-3 cursor-pointer" src="@/assets/images/copy-icon.svg" alt="">
              </div>
            </div>
            <div class="small-card sm:p-4 py-3 px-2 flex items-center justify-between">
              <p class="lg:text-base sm:text-sm text-xs">
                <span class="text-darkblue">{{ $t('City/Area:') }}</span>
                <span class="font-semibold text-blue ml-1">{{ $t('Giza') }}</span>
              </p>
              <div class="relative inline-block">
                <span id="city" class="tooltip text-xs py-1 px-2 hidden">{{ $t(tooltipText) }}</span>
                <img @click="copied('Giza', 'city')" class="sm:w-4 w-3 cursor-pointer" src="@/assets/images/copy-icon.svg" alt="">
              </div>
            </div>
            <div class="small-card sm:p-4 py-3 px-2 flex items-center justify-between">
              <p class="lg:text-base sm:text-sm text-xs">
                <span class="text-darkblue">{{ $t('Country:') }}</span>
                <span class="font-semibold text-blue ml-1">{{ $t('Egypt') }}</span>
              </p>
              <div class="relative inline-block">
                <span id="country" class="tooltip text-xs py-1 px-2 hidden">{{ $t(tooltipText) }}</span>
                <img @click="copied('Egypt', 'country')" class="sm:w-4 w-3 cursor-pointer" src="@/assets/images/copy-icon.svg" alt="">
              </div>
            </div>
            <div class="small-card sm:p-4 py-3 px-2 flex items-center justify-between">
              <p class="lg:text-base sm:text-sm text-xs">
                <span class="text-darkblue">{{ $t('Mobile Number:') }}</span>
                <span class="font-semibold text-blue ml-1">{{ userData.phone }}</span>
              </p>
              <div class="relative inline-block">
                <span id="phone" class="tooltip text-xs py-1 px-2 hidden">{{ $t(tooltipText) }}</span>
                <img @click="copied(userData.phone, 'phone')" class="sm:w-4 w-3 cursor-pointer" src="@/assets/images/copy-icon.svg" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-6 card rounded-lg lg:p-10 sm:p-6 p-4">
        <p class="text-eggplant capitalize font-semibold md:text-xl text-lg">{{ $t('Get started by following the below 4 steps:') }}</p>
        <div class="md:mt-8 mt-6">
          <div class="md:mt-0 mt-2 sm:flex items-center" :class="index%2 !== 0 ? 'rtl text-left' : ''" v-for="(step, index) in steps" :key="index">
            <div class="outlined-border sm:w-60 w-48 p-4 rounded-full" :class="index%2 === 0 ? 'left pl-4' : 'right pr-4'">
              <div class="dashed-border rounded-full step-img-container p-1">
                <img class="w-full h-full" :src="step.image" alt="">
              </div>
            </div>
            <div class="sm:mt-0 mt-2 step-content-container lg:w-1/2 w-full p-4" :class="index%2 === 0 ? 'left' : 'right'">
              <div class="step-content md:pt-4 pt-6 px-2" :class="index%2 === 0 ? 'left lg:pr-16 md:pr-14 pr-10' : 'right lg:pl-16 md:pl-14 pl-10'">
                <p class="text-eggplant font-bold md:text-xl text-base">{{ $t(step.title) }}</p>
                <p class="font-medium md:text-base text-sm text-gray">{{ $t(step.body) }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div @click="showMore = !showMore" class="important-notes lg:px-24 sm:px-10 px-5 py-6">
      <div class="flex items-center justify-between">
        <p class="font-bold leading-tight text-lg">{{ $t('Important Notes:') }}</p>
        <span class="material-icons text-eggplant cursor-pointer" v-if="showMore">expand_less</span>
        <span class="material-icons text-eggplant cursor-pointer" v-else>expand_more</span>
      </div>
      <div ref="content" :class="showMore ? 'show-notes md:mt-5 mt-3' : 'hide-notes'">
        <ul class="pl-4 text-darkblue font-medium md:text-base text-sm">
          <li>{{ $t('Your box number is the only identifier for your package. If your box number was not added to the address field of your online order, we won’t be able to identify your package when we received it.') }}</li>
          <li>{{ $t('Your shipments need to be paid for online. Our team cannot process Cash-On-Delivery shipments.') }}</li>
          <li>{{ $t('Please make sure to add our team’s local phone number in the telephone field.') }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { sendRequest } from '@/axios/requestHelper'
import imgStep1 from '../../assets/images/step1-img.svg'
import imgStep2 from '../../assets/images/step2-img.svg'
import imgStep3 from '../../assets/images/step3-img.svg'
import imgStep4 from '../../assets/images/step4-img.svg'


export default {
  data () {
    return {
      userData: {},
      steps: [
        {
          image: imgStep1,
          title: 'Step 1',
          body: 'Use your ShipBlu box address when you order from any Egyptian online store'
        },
        {
          image: imgStep2,
          title: 'Step 2',
          body: 'Pay your order online and once we receive it, we will display the received products on your account'
        },
        {
          image: imgStep3,
          title: 'Step 3',
          body: 'Pay the shipment’s international shipping fees online from your account'
        },
        {
          image: imgStep4,
          title: 'Step 4',
          body: 'Once your shipment is received in Khartoum, we will notify you to collect it from the nearest Shipblu location'
        }
      ],
      showMore: false,
      tooltipText: ''
    }
  },
  methods: {
    loadUserInfo () {
      sendRequest(this, `api/customers/${this.$store.state.AppActiveUser.userID.customer_id}/`, 'get', null, true,
        (response) => {
          this.userData = response.data
          localStorage.setItem('userData', JSON.stringify(response.data))
        }
      )
    },
    async copied (data, tooltipID) {
      try {
        await navigator.clipboard.writeText(data)
        this.tooltipText = 'Copied!'
        document.getElementById(tooltipID).classList.remove('hidden')
        setTimeout(() => {
          document.getElementById(tooltipID).classList.add('hidden')
        }, 1000)
      } catch ($e) {
        this.tooltipText = 'Cannot copy!'
        document.getElementById(tooltipID).classList.remove('hidden')
        setTimeout(() => {
          document.getElementById(tooltipID).classList.add('hidden')
        }, 1000)
      }
    }
  },
  created () {
    if (localStorage.getItem('userData') === null) {
      this.loadUserInfo()
    } else {
      this.userData = JSON.parse(localStorage.getItem('userData'))
    }
  }
}
</script>

<style lang="scss" scoped src="@/assets/css/styles/home.scss"></style>